import { ViewController } from 'elc-service';
import { action, computed, observable } from 'mobx';
import {
    IAutoReplenishment,
    IAutoReplenishOptions
} from '../../exported/auto-replenishment/AutoReplenishment';

export interface IAutoReplenishmentControllerConfig {
    autoReplenishment: IAutoReplenishOptions;
    onSelect(option: number): void;
}

export class AutoReplenishmentViewController extends ViewController {
    private config: IAutoReplenishmentControllerConfig;
    @observable public isAutoReplenishSelected = false;
    @observable public selectedAutoReplenishOption: number = 0;

    @action
    public initialize = (props: IAutoReplenishment) => {
        const { config } = props;
        this.config = config as IAutoReplenishmentControllerConfig;
    };

    @computed
    public get componentConfig() {
        const { autoReplenishment = {} } = this.config || {};
        const { ...autoReplenishmentConfig } = autoReplenishment;

        return { ...autoReplenishmentConfig };
    }

    @computed
    public get data() {
        const { intervals = [], toggleType, timingType } = this.componentConfig;

        return {
            componentConfig: this.componentConfig,
            isAutoReplenishSelected: this.isAutoReplenishSelected,
            selectedAutoReplenishOption: this.selectedAutoReplenishOption,
            intervals,
            toggleType,
            timingType
        };
    }

    @computed
    public get infoModalConfig() {
        const { infoPopupTriggerType, infoPopupNodeId } = this.componentConfig;

        return {
            infoPopupTriggerType,
            infoPopupNodeId
        };
    }

    @action
    public setAutoReplenishOption = (option: number) => {
        this.selectedAutoReplenishOption = option;
        this.config.onSelect(option);
    };

    @action
    public setAutoReplenishSelection = (isSelected: boolean) => {
        this.isAutoReplenishSelected = isSelected;
        if (!isSelected) {
            this.setAutoReplenishOption(0);
        }
    };

    public toggleAutoReplenishSelection = () => {
        this.setAutoReplenishSelection(!this.isAutoReplenishSelected);
    };
}
