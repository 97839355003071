import { diContainer, serviceNames } from './diContainer';
import { Service, ServiceInitializer } from 'elc-service';
import { AUTO_REPLENISH } from '@estee/elc-service-view-names';

const { name, version } = __serviceInfo__;

export class SubscriptionsService extends Service {
    constructor(serviceInitializer: ServiceInitializer) {
        super(name, version, serviceInitializer);

        this.registerView(
            AUTO_REPLENISH,
            () =>
                import(
                    /* webpackChunkName: 'Auto Replenishment' */
                    '../exported/auto-replenishment/AutoReplenishment'
                ),
            () => diContainer.get(serviceNames.autoReplenishmentViewController)
        );
    }
}
