//tslint:disable:export-name
import { Container } from 'hypo-container';
import { ConfigStore } from './ConfigStore';
import { ServiceBusController } from './ServiceBusController';
import { getDecorators } from 'elc-service';
import { SubscriptionsService } from './SubscriptionsService';
import { AutoReplenishmentViewController } from '../internal/controllers/AutoReplenishmentViewController';

export const diContainer = new Container();
export const serviceNames = {
    subscriptionsService: 'SUBSCRIPTIONS_SERVICE',
    configStore: 'CONFIG_STORE',
    serviceBusController: 'SERVICE_BUS_CONTROLLER',
    autoReplenishmentViewController: 'AUTO_REPLENISHMENT_VIEW_CONTROLLER'
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setConfig = (configJSON: any) => {
    diContainer.config = configJSON;
};

diContainer
    .register(serviceNames.subscriptionsService, (c) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return new SubscriptionsService((config: any) => {
            setConfig(config);

            return c.get(serviceNames.serviceBusController);
        });
    })
    .register(serviceNames.configStore, (c) => new ConfigStore(c.config))
    .register(serviceNames.serviceBusController, () => new ServiceBusController())
    .register(
        serviceNames.autoReplenishmentViewController,
        diContainer.factory((c) => new AutoReplenishmentViewController())
    );

const { lazyInject } = getDecorators(diContainer);

export { lazyInject };
